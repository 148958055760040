import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Beer = ({ data }) => {
  const {
    gcms: {
      beers: [beer],
    },
  } = data

  return (
    <Layout pageName="beers">
      <SEO title={beer.name} />
      <h1>
        <span>{beer.name}</span>
      </h1>
      <div>
        <img src={beer.image.url} alt={beer.name} />
        <h5>
          <span>{beer.abv}% abv</span>
        </h5>
        <div class="article">
          {/* <h4>PRIM AND PROPER</h4> */}
          <p>{beer.description}</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query loadBeerQuery($slug: String!) {
    gcms {
      beers(where: { slug: $slug }) {
        name
        description
        image {
          url
        }
        abv
      }
    }
  }
`
export default Beer
